import React from 'react'

const Footer = () => {

    return (

        <footer>
            &copy; 2024 EUC Online Service GmbH Köln
        </footer>
    );

};

export default Footer;