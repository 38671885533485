import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function Leistungen() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>Unsere Leistungen | EUC Online Servive in Köln</title>
            </Helmet>

            <h1>Leistungen</h1>

            <p>Ein Auszug dessen, was wir für Sie tun können und einige Szenarien, in denen wir uns auskennen.</p>

            <h2>Content Management</h2>

            <p>Wir richten Ihren Wordpress- oder Drupal-Auftritt ein und erstellen zu Ihnen und Ihrer Corporate Identity passende "Themes" bzw. "Child Themes".<br />Ein Beispiel: <a href="https://www.neuehorizonte.de" target="external">Neue Horizonte Köln e.V.</a></p>

            <p>Sie nutzen ein vor Jahren für Sie aufgesetztes CMS und wollen, nicht zuletzt aus Sicherheitsgründen, auf ein modernes System migrieren. Wie bekommen Sie Ihre Daten in das neue System? Hier können wir oft mit Skripten weiterhelfen, die Daten auslesen, sie modifizieren, an das Datenmodell des Zielsystems anpassen und dort einlesen.</p>

            <h2>Online-Shop</h2>

            <p>Nutzen Sie unser selbst entwickeltes Shop-System. Es ist extrem schnell und flexibel.<br />Ein Beispiel: <a href="https://www.carat-online.at" target="external">Carat Edelsteinhandel, Wien</a></p>

            <h2>Mail-Server</h2>

            <p>Eine gute Wahl ist &quot;Mailcow&quot;, eine flexible Open Source Lösung.</p>

            <h2>Datentransfer</h2>

            <p>Wenn Sie Daten über das Internet transportieren, gibt es viele Akteure, die versuchen werden, mitzulesen. Denen kann man das Leben schwer machen, indem man die eigentlichen Datenquellen und -senken durch Proxy-Server schützt und die Daten und Transportwege verschlüsselt.</p>

            <h2>Datenschutz</h2>
            <p>Wenn Sie Daten über die Nutzung Ihrer Web-Site sammeln wollen, müssen Sie nicht auf Google Analytics setzen &ndash; ein schickes Tool, vermeintlich auch noch kostenlos, Sie zahlen aber mit den Daten Ihrer Kunden. Eine selbst gehostete Instanz des "Matomo Analytics" Servers leistet gute Dienste: Die Kundendaten bleiben unter Ihrer Daten-Hoheit.</p>

            <h2>Domainverwaltung</h2>
            <p>Wir registrieren Ihre Internet-Domains und betreiben das DNS auf eigenen Servern.</p>

            <h2>Consulting</h2>
            <p>Da wir uns seit 30 Jahren mit Unix, Linux und BSD befassen und schon immer unsere eigenen Server betreiben, haben wir einige Erfahrung und beraten Sie gern.</p>

        </HelmetProvider>
    )
}

export default Leistungen