import React, { Component } from 'react';

class Hamburger extends Component {
    render() {
        return (
            <div className="hamburger">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
        )
    }
}

export default Hamburger;