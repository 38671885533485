import React, { Component } from 'react';

class Logo extends Component {
    render() {
        return (
            <div className="logo-main">
                <a href={"/"}><img src="./assets/euc-logo.png" alt="" /></a>
            </div>
        )
    }
}

export default Logo;