import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Datenschutz() {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Datenschutz | EUC Online Service Köln</title>
                <meta name="description" content="EUC Datenschutz"/>
                <meta name="author" content="Michael Heidel"/>
            </Helmet>

            <h1>Datenschutz</h1>

            <h2>Name und Kontakt des Verantwortlichen gem&auml;&szlig; Artikel 4 Abs. 7 DSGVO</h2>

            <p>EUC Online Service GmbH<br/>
                Machabäerstraße 28<br />
                50668 K&ouml;ln<br/>
                Michael Heidel<br/>
                E-Mail: <a href="mailto:info@euc.de">info@euc.de</a></p>

            <h2>Sicherheit und Schutz Ihrer personenbezogenen Daten</h2>

            <p>Wir betrachten es als unsere vorrangige Aufgabe, die Vertraulichkeit der von Ihnen bereitgestellten
                personenbezogenen Daten zu wahren und diese vor unbefugten Zugriffen zu sch&uuml;tzen. Deshalb wenden
                wir &auml;u&szlig;erste Sorgfalt und modernste Sicherheitsstandards an, um einen maximalen Schutz Ihrer
                personenbezogenen Daten zu gew&auml;hrleisten.</p>

            <p>Als privatrechtliches Unternehmen&nbsp;unterliegen wir den Bestimmungen der europ&auml;ischen
                Datenschutzgrundverordnung (DSGVO) und den Regelungen des Bundesdatenschutzgesetzes (BDSG). Wir haben
                technische und organisatorische Ma&szlig;nahmen getroffen, die sicherstellen, dass die
                Vorschriften &uuml;ber den Datenschutz sowohl von uns, als auch von unseren externen Dienstleistern
                beachtet werden.</p>

            <h2>Begriffsbestimmungen</h2>

            <p>Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtm&auml;&szlig;ige Weise, nach Treu und
                Glauben und in einer f&uuml;r die betroffene Person nachvollziehbaren Weise verarbeitet werden
                (&bdquo;Rechtm&auml;&szlig;igkeit, Verarbeitung nach Treu und Glauben, Transparenz&ldquo;). Um dies zu
                gew&auml;hrleisten, informieren wir Sie &uuml;ber die einzelnen gesetzlichen Begriffsbestimmungen, die
                auch in dieser Datenschutzerkl&auml;rung verwendet werden:</p>

            <ol>
                <li>&nbsp;Personenbezogene Daten<br/>
                    &bdquo;Personenbezogene Daten&ldquo; sind alle Informationen, die sich auf eine identifizierte oder
                    identifizierbare nat&uuml;rliche Person (im Folgenden &bdquo;betroffene Person&ldquo;) beziehen; als
                    identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt, insbesondere
                    mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
                    Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
                    Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
                    oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind.
                </li>
                <li>Verarbeitung<br/>
                    &bdquo;Verarbeitung&ldquo; ist jeder, mit oder ohne Hilfe automatisierter Verfahren,
                    ausgef&uuml;hrter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten
                    wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder
                    Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
                    durch &Uuml;bermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
                    Verkn&uuml;pfung, die Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung.
                </li>
                <li>Einschr&auml;nkung der Verarbeitung<br/>
                    &bdquo;Einschr&auml;nkung der Verarbeitung&ldquo; ist die Markierung gespeicherter personenbezogener
                    Daten mit dem Ziel, ihre k&uuml;nftige Verarbeitung einzuschr&auml;nken.
                </li>
                <li>Profiling<br/>
                    &bdquo;Profiling&ldquo; ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die
                    darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte pers&ouml;nliche
                    Aspekte, die sich auf eine nat&uuml;rliche Person beziehen, zu bewerten, insbesondere um Aspekte
                    bez&uuml;glich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, pers&ouml;nliche Vorlieben,
                    Interessen, Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen
                    Person zu analysieren oder vorherzusagen.
                </li>
                <li>Pseudonymisierung<br/>
                    &bdquo;Pseudonymisierung&ldquo; ist die Verarbeitung personenbezogener Daten in einer Weise, dass
                    die personenbezogenen Daten ohne Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer
                    spezifischen betroffenen Person zugeordnet werden k&ouml;nnen, sofern diese zus&auml;tzlichen
                    Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma&szlig;nahmen
                    unterliegen, die gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer identifizierten
                    oder identifizierbaren nat&uuml;rlichen Person zugewiesen werden k&ouml;nnen.
                </li>
                <li>Dateisystem<br/>
                    &bdquo;Dateisystem&ldquo; ist jede strukturierte Sammlung personenbezogener Daten, die nach
                    bestimmten Kriterien zug&auml;nglich sind, unabh&auml;ngig davon, ob diese Sammlung zentral,
                    dezentral oder nach funktionalen oder geografischen Gesichtspunkten geordnet gef&uuml;hrt wird.
                </li>
                <li>Verantwortlicher<br/>
                    &bdquo;Verantwortlicher&ldquo; ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                    Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
                    Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser
                    Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so k&ouml;nnen der
                    Verantwortliche beziehungsweise die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder
                    dem Recht der Mitgliedstaaten vorgesehen werden.
                </li>
                <li>Auftragsverarbeiter<br/>
                    &bdquo;Auftragsverarbeiter&ldquo; ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                    Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
                    verarbeitet.
                </li>
                <li>Empf&auml;nger<br/>
                    &bdquo;Empf&auml;nger&ldquo; ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                    Einrichtung oder andere Stelle, denen personenbezogene Daten offengelegt werden, unabh&auml;ngig
                    davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Beh&ouml;rden, die im Rahmen eines
                    bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                    m&ouml;glicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empf&auml;nger; die
                    Verarbeitung dieser Daten durch die genannten Beh&ouml;rden erfolgt im Einklang mit den geltenden
                    Datenschutzvorschriften gem&auml;&szlig; den Zwecken der Verarbeitung.
                </li>
                <li>Dritter<br/>
                    &bdquo;Dritter&ldquo; ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung
                    oder andere Stelle, au&szlig;er der betroffenen Person, dem Verantwortlichen, dem
                    Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen
                    oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
                </li>
                <li>Einwilligung<br/>
                    Eine &bdquo;Einwilligung&ldquo; der betroffenen Person ist jede freiwillig f&uuml;r den bestimmten
                    Fall, in informierter Weise und unmissverst&auml;ndlich abgegebene Willensbekundung in Form einer
                    Erkl&auml;rung oder einer sonstigen eindeutigen best&auml;tigenden Handlung, mit der die betroffene
                    Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
                    einverstanden ist.
                </li>
            </ol>

            <h2>Rechtm&auml;&szlig;igkeit der Verarbeitung</h2>

            <p>Die Verarbeitung personenbezogener Daten ist nur rechtm&auml;&szlig;ig, wenn f&uuml;r die Verarbeitung
                eine Rechtsgrundlage besteht. Rechtsgrundlage f&uuml;r die Verarbeitung k&ouml;nnen
                gem&auml;&szlig; Artikel 6 Abs. 1<br/>
                lit. a &ndash; f DSGVO insbesondere sein:</p>

            <ol>
                <li>Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der sie betreffenden
                    personenbezogenen Daten f&uuml;r einen oder mehrere bestimmte Zwecke gegeben;
                </li>
                <li>die Verarbeitung ist f&uuml;r die Erf&uuml;llung eines Vertrags, dessen Vertragspartei die
                    betroffene Person ist, oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, die
                    auf Anfrage der betroffenen Person erfolgen;
                </li>
                <li>die Verarbeitung ist zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich, der der
                    Verantwortliche unterliegt;
                </li>
                <li>die Verarbeitung ist erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer
                    anderen nat&uuml;rlichen Person zu sch&uuml;tzen;
                </li>
                <li>die Verarbeitung ist f&uuml;r die Wahrnehmung einer Aufgabe erforderlich, die im &ouml;ffentlichen
                    Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem
                    Verantwortlichen &uuml;bertragen wurde;die Verarbeitung ist zur Wahrung der berechtigten Interessen
                    des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte
                    und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten
                    erfordern, &uuml;berwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind
                    handelt.
                </li>
            </ol>

            <h2>Information &uuml;ber die Erhebung personenbezogener Daten</h2>

            <p>(1) Im Folgenden informieren wir &uuml;ber die Erhebung personenbezogener Daten bei Nutzung unserer
                Website. Personenbezogene Daten sind z.&thinsp;B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>

            <p>(2) Bei einer Kontaktaufnahme mit uns per E-Mail oder &uuml;ber ein Kontaktformular werden die von Ihnen
                mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um
                Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die
                Speicherung nicht mehr erforderlich ist, oder die Verarbeitung wird eingeschr&auml;nkt, falls
                gesetzliche Aufbewahrungspflichten bestehen.</p>

            <h2>Erhebung personenbezogener Daten bei Besuch unserer Website</h2>

            <p>Bei der blo&szlig; informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns
                anderweitig Informationen &uuml;bermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser
                an unseren Server &uuml;bermittelt. Wenn Sie unsere Website betrachten m&ouml;chten, erheben wir die
                folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
                die Stabilit&auml;t und Sicherheit zu gew&auml;hrleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f
                DSGVO):</p>

            <ul>
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils &uuml;bertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfl&auml;che</li>
                <li>Sprache und Version der Browsersoftware.</li>
            </ul>

            <h2>Einsatz von Cookies</h2>

            <p>Wir setzen keine Cookies ein.</p>

            <h2>Kinder</h2>

            <p>Unser Angebot richtet sich grunds&auml;tzlich an Erwachsene. Personen unter 18 Jahren sollten ohne
                Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an
                uns &uuml;bermitteln.</p>

            <h2>Rechte der betroffenen Person</h2>

            <h3>(1) Widerruf der Einwilligung</h3>

            <p>Sofern die Verarbeitung der personenbezogenen Daten auf einer erteilten Einwilligung beruht, haben Sie
                jederzeit das Recht, die Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird die
                Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                ber&uuml;hrt.</p>

            <p>F&uuml;r die Aus&uuml;bung des Widerrufsrechts k&ouml;nnen Sie sich jederzeit an uns wenden.</p>

            <h3>(2) Recht auf Best&auml;tigung</h3>

            <p>Sie haben das Recht, von dem Verantwortlichen eine Best&auml;tigung dar&uuml;ber zu verlangen, ob wir sie
                betreffende personenbezogene Daten verarbeiten. Die Best&auml;tigung k&ouml;nnen Sie jederzeit unter den
                oben genannten Kontaktdaten verlangen.</p>

            <h3>(3) Auskunftsrecht</h3>

            <p>Sofern personenbezogene Daten verarbeitet werden, k&ouml;nnen Sie jederzeit Auskunft &uuml;ber diese
                personenbezogenen Daten und &uuml;ber folgenden Informationen verlangen:</p>

            <ol>
                <li>die Verarbeitungszwecke;</li>
                <li>den Kategorien personenbezogener Daten, die verarbeitet werden;</li>
                <li>die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen die personenbezogenen
                    Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empf&auml;ngern in
                    Drittl&auml;ndern oder bei internationalen Organisationen;
                </li>
                <li>falls m&ouml;glich, die geplante Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden,
                    oder, falls dies nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung dieser Dauer;
                </li>
                <li>das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie betreffenden personenbezogenen
                    Daten oder auf Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder eines
                    Widerspruchsrechts gegen diese Verarbeitung;
                </li>
                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde;</li>
                <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle
                    verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten;
                </li>
                <li>das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling
                    gem&auml;&szlig; Artikel 22 Abs&auml;tze 1 und 4 DSGVO und &ndash; zumindest in diesen
                    F&auml;llen &ndash; aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik sowie die
                    Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung f&uuml;r die betroffene
                    Person.
                </li>
            </ol>

            <p>Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation &uuml;bermittelt,
                so haben Sie das Recht, &uuml;ber die geeigneten Garantien gem&auml;&szlig; Artikel 46 DSGVO im
                Zusammenhang mit der &Uuml;bermittlung unterrichtet zu werden. Wir stellen eine Kopie der
                personenbezogenen Daten, die Gegenstand der Verarbeitung sind, zur Verf&uuml;gung. F&uuml;r alle
                weiteren Kopien, die Sie Person beantragen, k&ouml;nnen wir ein angemessenes Entgelt auf der Grundlage
                der Verwaltungskosten verlangen. Stellen Sie den Antrag elektronisch, so sind die Informationen in einem
                g&auml;ngigen elektronischen Format zur Verf&uuml;gung zu stellen, sofern er nichts anderes angibt. Das
                Recht auf Erhalt einer Kopie gem&auml;&szlig; Absatz 3 darf die Rechte und Freiheiten anderer Personen
                nicht beeintr&auml;chtigen.</p>

            <h3>(4) Recht auf Berichtigung</h3>

            <p>Sie haben das Recht, von uns unverz&uuml;glich die Berichtigung Sie betreffender unrichtiger
                personenbezogener Daten zu verlangen. Unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung haben Sie
                das Recht, die Vervollst&auml;ndigung unvollst&auml;ndiger personenbezogener Daten &ndash; auch mittels
                einer erg&auml;nzenden Erkl&auml;rung &ndash; zu verlangen.</p>

            <h3>(5) Recht auf L&ouml;schung (&bdquo;Recht auf vergessen werden&ldquo;)</h3>

            <p>Sie haben das Recht, von dem Verantwortlichen zu verlangen, dass Sie betreffende personenbezogene Daten
                unverz&uuml;glich gel&ouml;scht werden, und wir sind verpflichtet, personenbezogene Daten
                unverz&uuml;glich zu l&ouml;schen, sofern einer der folgenden Gr&uuml;nde zutrifft:</p>

            <p>a. Die personenbezogenen Daten sind f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise
                verarbeitet wurden, nicht mehr notwendig.<br/>
                b. Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
                gem&auml;&szlig; Artikel 6 Absatz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO st&uuml;tzte,
                und es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung.<br/>
                c. Die betroffene Person legt gem&auml;&szlig; Artikel 21 Absatz 1 DSGVO Widerspruch gegen die
                Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor,
                oder die betroffene Person legt gem&auml;&szlig; Artikel 21 Absatz 2 DSGVO Widerspruch gegen die
                Verarbeitung ein.<br/>
                d. Die personenbezogenen Daten wurden unrechtm&auml;&szlig;ig verarbeitet.<br/>
                e. Die L&ouml;schung der personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung
                nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
                unterliegt.<br/>
                f. Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft
                gem&auml;&szlig; Artikel 8 Absatz 1 DSGVO erhoben.</p>

            <p>Hat der Verantwortliche die personenbezogenen Daten &ouml;ffentlich gemacht und ist er
                gem&auml;&szlig; Absatz 1 zu deren L&ouml;schung verpflichtet, so trifft er unter Ber&uuml;cksichtigung
                der verf&uuml;gbaren Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen, auch
                technischer Art, um f&uuml;r die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten
                verarbeiten, dar&uuml;ber zu informieren, dass eine betroffene Person von ihnen die L&ouml;schung aller
                Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen
                Daten verlangt hat.</p>

            <p>Das Recht auf L&ouml;schung (&bdquo;Recht auf vergessen werden&ldquo;) besteht nicht, soweit die
                Verarbeitung erforderlich ist:</p>

            <ul>
                <li>zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information;</li>
                <li>zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union
                    oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
                    Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt
                    erfolgt, die dem Verantwortlichen &uuml;bertragen wurde;
                </li>
                <li>aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der &ouml;ffentlichen Gesundheit
                    gem&auml;&szlig; Artikel 9 Absatz 2 Buchstaben h und i sowie Artikel 9 Absatz 3 DSGVO;
                </li>
                <li>f&uuml;r im &ouml;ffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                    Forschungszwecke oder f&uuml;r statistische Zwecke gem&auml;&szlig; Artikel 89 Absatz 1 DSGVO,
                    soweit das in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser
                    Verarbeitung unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt, oder
                </li>
                <li>zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</li>
            </ul>

            <h3>(6) Recht auf Einschr&auml;nkung der Verarbeitung</h3>

            <p>Sie haben das Recht, von uns die Einschr&auml;nkung der Verarbeitung ihrer personenbezogenen Daten zu
                verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</p>

            <ol>
                <li>die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar
                    f&uuml;r eine Dauer, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der
                    personenbezogenen Daten zu &uuml;berpr&uuml;fen,
                </li>
                <li>die Verarbeitung unrechtm&auml;&szlig;ig ist und die betroffene Person die L&ouml;schung der
                    personenbezogenen Daten ablehnt und stattdessen die Einschr&auml;nkung der Nutzung der
                    personenbezogenen Daten verlangt;
                </li>
                <li>der Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung nicht
                    l&auml;nger ben&ouml;tigt, die betroffene Person sie jedoch zur Geltendmachung, Aus&uuml;bung oder
                    Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigt, oder
                </li>
                <li>die betroffene Person Widerspruch gegen die Verarbeitung gem&auml;&szlig; Artikel 21 Absatz 1 DSGVO
                    eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gr&uuml;nde des Verantwortlichen
                    gegen&uuml;ber denen der betroffenen Person &uuml;berwiegen.
                </li>
            </ol>

            <p>Wurde die Verarbeitung gem&auml;&szlig; den oben genannten Voraussetzungen eingeschr&auml;nkt, so werden
                diese personenbezogenen Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Einwilligung der
                betroffenen Person oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
                oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
                eines wichtigen &ouml;ffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet.</p>

            <p>Um das Recht auf Einschr&auml;nkung der Verarbeitung geltend zu machen, kann sich die betroffene Person
                jederzeit an uns unter den oben angegebenen Kontaktdaten wenden.</p>

            <h3>(7) Recht auf Daten&uuml;bertragbarkeit</h3>

            <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in
                einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht,
                diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
                personenbezogenen Daten bereitgestellt wurden, zu &uuml;bermitteln, sofern:</p>

            <ol>
                <li>die Verarbeitung auf einer Einwilligung gem&auml;&szlig; Artikel 6 Absatz 1 Buchstabe a oder Artikel
                    9 Absatz 2 Buchstabe a oder auf einem Vertrag gem&auml;&szlig; Artikel 6 Absatz 1 Buchstabe b DSGVO
                    beruht und
                </li>
                <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
            </ol>

            <p>Bei der Aus&uuml;bung des Rechts auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Absatz 1 haben Sie das
                Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen zu einem anderen
                Verantwortlichen &uuml;bermittelt werden, soweit dies technisch machbar ist. Die Aus&uuml;bung des
                Rechts auf Daten&uuml;bertragbarkeit l&auml;sst das Recht auf L&ouml;schung (&bdquo;Recht auf Vergessen
                werden&ldquo;) unber&uuml;hrt. Dieses Recht gilt nicht f&uuml;r eine Verarbeitung, die f&uuml;r die
                Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder in
                Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde.</p>

            <h3>(8) Widerspruchsrecht</h3>

            <p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
                die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1
                Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
                Bestimmungen gest&uuml;tztes Profiling. Der Verantwortliche verarbeitet die personenbezogenen Daten
                nicht mehr, es sei denn, er kann zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
                nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die
                Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>

            <p>Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so haben SIe das Recht,
                jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
                derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher
                Direktwerbung in Verbindung steht. Widersprechen Sie der Verarbeitung f&uuml;r Zwecke der Direktwerbung,
                so werden die personenbezogenen Daten nicht mehr f&uuml;r diese Zwecke verarbeitet.</p>

            <p>Im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft k&ouml;nne Sie ungeachtet der
                Richtlinie 2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren aus&uuml;ben, bei denen
                technische Spezifikationen verwendet werden.</p>

            <p>Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie
                betreffende Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder
                historischen Forschungszwecken oder zu statistischen Zwecken gem&auml;&szlig; Artikel 89 Absatz 1
                erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erf&uuml;llung einer
                im &ouml;ffentlichen Interesse liegenden Aufgabe erforderlich.</p>

            <p>Das Widerspruchsrecht k&ouml;nnen Sie jederzeit aus&uuml;ben, indem Sie sich an den jeweiligen
                Verantwortlichen wenden.</p>

            <h3>(9) Automatisierte Entscheidungen im Einzelfall einschlie&szlig;lich Profiling</h3>

            <p>Sie haben das Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten
                Verarbeitung &ndash; einschlie&szlig;lich Profiling &ndash; beruhenden Entscheidung unterworfen zu
                werden, die Ihnen gegen&uuml;ber rechtliche Wirkung entfaltet oder Sie in &auml;hnlicher Weise erheblich
                beeintr&auml;chtigt. Dies gilt nicht, wenn die Entscheidung:</p>

            <ol>
                <li>f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen der betroffenen Person und
                    dem Verantwortlichen erforderlich ist,
                </li>
                <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
                    unterliegt, zul&auml;ssig ist und diese Rechtsvorschriften angemessene Ma&szlig;nahmen zur Wahrung
                    der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder
                </li>
                <li>mit ausdr&uuml;cklicher Einwilligung der betroffenen Person erfolgt.</li>
            </ol>

            <p>Der Verantwortliche trifft angemessene Ma&szlig;nahmen, um die Rechte und Freiheiten sowie die
                berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des
                Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
                Anfechtung der Entscheidung geh&ouml;rt.</p>

            <p>Dieses Recht kann die betroffene Person jederzeit aus&uuml;ben, indem sie sich an den jeweiligen
                Verantwortlichen wendet.</p>

            <h3>(10) Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</h3>

            <p>Sie haben zudem, unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs,
                das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres
                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es, wenn die
                betroffene Person der Ansicht ist, dass die Verarbeitung der sie betreffenden personenbezogenen Daten
                gegen diese Verordnung verst&ouml;&szlig;t.</p>

            <h3>(11) Recht auf wirksamen gerichtlichen Rechtsbehelf</h3>

            <p>Sie haben unbeschadet eines verf&uuml;gbaren verwaltungsrechtlichen oder au&szlig;ergerichtlichen
                Rechtsbehelfs einschlie&szlig;lich des Rechts auf Beschwerde bei einer Aufsichtsbeh&ouml;rde
                gem&auml;&szlig; Artikel 77 DSGVO das Recht auf einen wirksamen gerichtlichen Rechtsbehelf, wenn sie der
                Ansicht ist, dass die ihr aufgrund dieser Verordnung zustehenden Rechte infolge einer nicht im Einklang
                mit dieser Verordnung stehenden Verarbeitung ihrer personenbezogenen Daten verletzt wurden.</p>

            <p>Wir bedienen uns externer Dienstleister (Auftragsverarbeiter). Mit diesen bestehen Vereinbarungen zur
                Auftragsdatenverarbeitung, um den Schutz Ihrer personenbezogenen Daten zu gew&auml;hrleisten.</p>

            <p>Wir arbeiten mit folgenden Dienstleistern zusammen:</p>

            <ul>
                <li>NetCologne Gesellschaft für Telekommunikation mbH<br />
                    Am Coloneum 9, 50829 Köln
                </li>
                <li>Knipp Medien und Kommunikation GmbH<br/>
                    Technologiepark, Martin-Schmei&szlig;er-Weg 9, 44227 Dortmund
                </li>
            </ul>


        </HelmetProvider>
    )
}

export default Datenschutz