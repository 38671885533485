import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function Home() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>EUC Online Servive in Köln</title>
            </Helmet>

            <h1>Willkommen</h1>

            <p>Unser Unternehmen entwickelt, betreibt und pflegt professionelle Internet-Anwendungen aller Art und arbeitet seit 1996 (zunächst als GbR, seit 1999 als GmbH) in Köln.
            </p>

            <p>Alle Dienste (Firewall, E-Mail, DNS, Web-Server) laufen auf unseren eigenen Servern im professionellen Rechenzentrum in Köln. Wir stellen dedizierte physikalische Maschinen sowie virtuelle Maschinen und Docker-Container im Internet bereit. Wir betreiben unsere Systeme unter Linux und BSD.</p>

            <p>Unsere Anwendungen sind sowohl solche &quot;von der Stange&quot; wie OpenCms, Wordpress und Online-Shops, als auch bei uns nach Ihren Vorgaben programmierte Applikationen. Wir betreuen Ihre Anwendung auch, wenn Sie sie auf Servern anderer Dienstleister hosten. Und wir migrieren Ihre in die Jahre gekommene Anwendung auf moderne Systeme.</p>

            <p>"Weniger ist mehr": Wir setzen nicht jeden Trend um, den das Web hergibt, bleiben aber stets auf dem Laufenden und konzentrieren uns auf das wesentliche. Unsere Anwendungen sind schlank und performant.</p>

            <p>Lesen Sie einen <a href="/leistungen">Auszug unserer Leistungen</a> &ndash; fragen Sie uns und lassen Sie sich fachkundig beraten!</p>

        </HelmetProvider>
    )
}

export default Home