import React from "react"
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'

import Home from './Home'
import Leistungen from './Leistungen'
import About from './About'
import Impressum from './Impressum'
import Datenschutz from './Datenschutz'

import './css/style.css'

//TODO Static Export

const App = () => {
    return (
        <div className="App">

            <BrowserRouter>
                <NavBar />
                <div className="content">
                <Routes>
                    <Route path="/" element={
                        <Home />
                    } />
                    <Route path="/leistungen" element={
                        <Leistungen />
                    } />
                    <Route path="/about" element={
                        <About />
                    } />
                    <Route path="/impressum" element={
                        <Impressum />
                    } />
                    <Route path="/datenschutz" element={
                        <Datenschutz />
                    } />
                </Routes>
                </div>

            </BrowserRouter>

            <Footer />

        </div>

    )
}

export default App;
