import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from './hamburger'
import Logo from './logo'


const Navbar = () => {

    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo">
                    <Logo />
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <span className={`${showNavbar ? 'cross' : 'hamburger'}`}
                          title={`${showNavbar ? 'Navigation schließen' : 'Navigation öffnen'}`} >
                    <Hamburger />
                </span>
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/leistungen">Leistungen</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/impressum">Impressum</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
