import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Impressum() {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Impressum</title>
                <meta name="description" content="EUC Impressum"/>
                <meta name="author" content="Michael Heidel"/>
            </Helmet>

            <h1>Impressum</h1>

            <p>siehe auch: <a href={"/datenschutz"}>Datenschutz</a></p>

            <table className="flex">
            <tbody>
            <tr>
                    <td>Anschrift</td>
                    <td>Machabäerstraße 28<br />50668 Köln</td>
                </tr>

                <tr>
                    <td>Kontakt</td>
                    <td>Telefon: +49 221 921323-0<br />Fax: +49 221 921323-8<br />E-Mail: info@euc.de</td>
                </tr>

                <tr>
                    <td>Geschäftsführer</td>
                    <td>Michael Heidel</td>
                </tr>

                <tr>
                    <td>Handelsregister</td>
                    <td>Köln HRB 32038</td>
                </tr>

                <tr>
                    <td>USt-IdNr</td>
                    <td>DE202543632</td>
                </tr>
            </tbody>
            </table>

            <p>Inhaltlich verantwortlich gemäß § 55 RStV: Michael Heidel (Anschrift wie oben).</p>
        </HelmetProvider>
    )
}

export default Impressum