import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function About() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>Über EUC Online Servive in Köln</title>
            </Helmet>

            <h1>Über uns ...</h1>

            <p>Das "Gesicht" hinter EUC ist Michael Heidel.</p>

            <p>Gebürtiger Kölner, Schullaufbahn mit jahrelangem Engagement in der katholischen Jugendverbandsarbeit (KSJ), Abitur 1978 am Humboldt-Gymnasium Köln. Studium der Diplom-Physik an der Universität zu Köln. Mitarbeit bei der statistischen Auswertung wissenschaftlicher Studien an der Universitäts-Augenklinik zu Köln, Programmierung eigener Statistik-Software (seinerzeit unter Turbo-Pascal).</p>

            <p>1993 Gründung des eingetragenen gemeinnützigen Vereins "Neue Horizonte Köln" mit dem Ziel, zur Verbesserung der Gesundheitssituation in Peru beizutragen. Seit 2015 Vorsitzender des Trägervereins der Katholischen Studierenden Jugend in der Erzdiözese Köln e.V.</p>

            <p>Programmierung zunächst auf Lochkarten in Fortran im Uni-Rechenzentrum und in Basic auf dem TI-59 (mit Magnetkarten), später auf dem TI-99/4a. Bau und Vertrieb von PCs und Netztechnik ("Heidel Datentechnik"). Seit 1992 praktische Erfahrung mit Linux (Start mit Kernel 0.96) und von den Anfängen des Internet bis heute technisch dabei. 1996 Gründung einer GbR als Vorläufer der 1999 gegründeten EUC Online Service GmbH, dort bis heute Gründungs-Geschäftsführer.</p>

        </HelmetProvider>
    )
}

export default About